import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Icons } from '../enums/icons.enum';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) { }


    registerIcons(): void {
      this.loadIcons(Icons, 'assets/icons');
    }

    private loadIcons(icons: Record<string, string>, url: string): void {
      Object.values(icons).forEach(iconName => {
        this.matIconRegistry.addSvgIcon(
          iconName,
          this.sanitizer.bypassSecurityTrustResourceUrl(`${url}/${iconName}.svg`)
        );
      });
    }

}
