<mat-toolbar class="toolbar flex-center">
    <span class="flex-center small-gap">
        <mat-icon svgIcon="etap-logo" class="etap-logo"></mat-icon>
        <span class="connect">Interop</span>
    </span>
    <span class="toolbar-spacer"></span>
    <div class="flex-center big-gap">
        <mat-icon svgIcon="notification"></mat-icon>
        <span>Caroline Jones</span>
        <mat-icon class="pointer" [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>
    </div>
    <mat-menu #menu="matMenu">			
        <button mat-menu-item>
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
        </button>		
    </mat-menu>	
</mat-toolbar>